import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { ArticleCard, ArticleDimension } from "@app/shared/components/ArticleCard"
import { PageSection } from "@app/shared/components/layout/PageSection"
import { TextCta } from "@app/shared/components/TextCta"
import { ArticleCardProps } from "@app/shared/types/article-card"
import { WordPressPost } from "@app/shared/utils/blog/wordpress-blog"

import { RecommendedArticles } from "../blog-post/RecommendedArticles"

import { CategoryMenu } from "./CategoryMenu"
import { HeroTitleBlog } from "./HeroTitleBlog"
import { flex, gridColumn, gridContainer, height, singleColumn } from "./styles/index.css"

type Props = {
  mostReadPosts?: Array<any>
  posts?: WordPressPost[]
}

// we had this strings hardcoded also in the previous version
const readMore = (lang: string): string => {
  switch (lang) {
    case "it":
      return "Continua a leggere"
    case "en":
      return "Continue reading"
    case "fr":
      return "Lire la suite"
    default:
      return "Continue reading"
  }
}

const postToPreview = (post: WordPressPost, lang: string): ArticleCardProps => ({
  articlePreview: post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "").replace("[&hellip;]", "..."),
  category: post.categories, //post.categories.length,
  continueReadingCtaText: readMore(lang),
  description: post.yoast_head_json?.description,
  imageSrc: post?.yoast_head_json?.og_image?.[0]?.url || "",
  link: `/${lang}/blog/${post.slug}`,
  publicationDate: new Date(post.date).toLocaleDateString(lang, {
    day: "numeric",
    month: "long",
  }),
  title: post.title.rendered,
})

export const BlogContent: React.FC<Props> = ({ mostReadPosts = [], posts = [] }) => {
  const { lang, t } = useTranslation()

  const currentPage = parseInt((useRouter().query?.number as string) || "1")

  return (
    <main>
      <HeroTitleBlog
        alt={t("blog:hero.alt")}
        mainTitle={t("blog:hero.mainTitle")}
        secondaryTitle={t("blog:hero.titleDescription")}
      />

      <CategoryMenu />

      <PageSection size="large">
        <div className={gridContainer}>
          {currentPage === 1 && posts.length > 0 && (
            <>
              <div className={gridColumn}>
                <ArticleCard
                  key={`featured-articles-0`}
                  dimension={ArticleDimension.Large}
                  props={postToPreview(posts[0], lang)}
                  trimAt={20}
                />
              </div>
              {posts[1] && (
                <ArticleCard
                  key={`featured-articles-1`}
                  dimension={ArticleDimension.Large}
                  props={postToPreview(posts[1], lang)}
                />
              )}
              {posts[2] && (
                <div className={singleColumn}>
                  <ArticleCard
                    key={`featured-articles-2`}
                    dimension={ArticleDimension.Large}
                    props={postToPreview(posts[2], lang)}
                    trimAt={20}
                  />
                </div>
              )}
            </>
          )}
          {posts.length > 0 && (
            <>
              {posts.length > 2 &&
                (currentPage === 1
                  ? posts
                      ?.slice(3)
                      ?.map((post, index) => (
                        <ArticleCard
                          key={`articles-after-newsletter-${index}`}
                          dimension={ArticleDimension.Small}
                          props={postToPreview(post, lang)}
                        />
                      ))
                  : posts
                      ?.slice(0)
                      ?.map((post, index) => (
                        <ArticleCard
                          key={`articles-after-newsletter-${index}`}
                          dimension={ArticleDimension.Small}
                          props={postToPreview(post, lang)}
                        />
                      )))}
            </>
          )}
        </div>

        <div className={flex}>
          <TextCta
            eventLabel="HomeAllArticles"
            label={t("blog:categoryMenu.allArticles")}
            url={`/${lang}/blog/all-articles`}
          />
        </div>
      </PageSection>

      {lang === "it" || lang === "en" ? (
        <RecommendedArticles greyCard={true} lightBlue={true} recentArticles={mostReadPosts.slice(0, 3)} />
      ) : (
        <div className={height} />
      )}
    </main>
  )
}
